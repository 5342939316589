<template>
  <Headercomp class="z-10"></Headercomp>
  <div class="container mx-auto mt-20">
    <router-view />
  </div>
  <Footercomp></Footercomp>

</template>

<script>
import Headercomp from './components/Layout/Hedader.vue'
import Footercomp from './components/Layout/Footer.vue'


export default {
  name: 'App',
  components: {
    Headercomp,
    Footercomp,

  }
}
</script>

<style>
@import url("@neshan-maps-platform/vue3-openlayers/dist/style.css");

body {
  background-color: rgb(186, 210, 219);
 font-family: Tahoma, Geneva, Verdana, sans-serif;
}

</style>
