import { createRouter, createWebHistory } from "vue-router";

import home from "@/views/homeCom.vue";
import about from "@/views/aboutCom.vue";
import contact from "@/views/contactCom.vue";
import machinery from "@/views/machineryCom.vue";
import carriers from "@/views/carriersCom.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/about",
    name: "Aboat",
    component: about,
  },
  {
    path: "/contact",
    name: "Contact",
    component: contact,
  },
  {
    path: "/machinery",
    name: "machinery",
    component: machinery,
  },
  {
    path: "/carriers",
    name: "carriers",
    component: carriers,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
