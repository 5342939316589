import { createApp } from "vue";
import i18n from './i18n'
import App from "./App.vue";
import "./index.css";
import  router  from "@/router/index";

const app = createApp(App);
app.use(i18n)
app.use(router)
app.mount("#app");
