<template>
    <Carousel :autoplay="3000" :wrap-around="true">
        <Slide class="z-0" v-for="slide in slides" :key="slide">
            <div class="carousel__item">
                <img :src="require(`../assets/photos/${slide.url}`)" :alt="slide.alt">
            </div>
        </Slide>

        <template #addons>
            <Navigation />
            <Pagination />
        </template>
    </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
    name: 'AutoplayC',
    data() {
        return {
            slides: [
                { url: 'one.jpg', alt: 'I love you nature' },
                { url: 'two.jpg', alt: 'I love you 2' },
                { url: 'three.jpg', alt: 'I love you 3' },
                { url: 'four.jpg', alt: 'I love you nature' }

            ]
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
    },

})
</script>
<style>
.carousel__item {
    max-height: 700px;
    /* height: 75vh; */
    width: 80%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding-top: 50px;
}

.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
}
</style>