<template lang="">
    <div :dir="$i18n.locale == 'ar' ?'rtl':'ltr'">
       <h1 class="text-3xl my-2">
        {{ $t("Contact") }}
       </h1>
       <div class="grid grid-cols-1 lg:grid-cols-2 gap-7 my-4">
            <div class=" bg-blue-300 p-3" style="">
               <div class="my-4">
                <div class="flex items-center">
                    <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                    </svg>
                   <span class="text-xl">
                    .
                   </span>
                    <p class="text-xl">
                         {{ $t("addressDubaioffice") }} :
                    </p>
                </div>
                <div class="text-xl pl-6">
                    {{ $t("officeAddressDubai") }} 
                </div>
               </div>
               <!-- ///////////////////////////////////////////////////////// -->
                <div class="my-4">
                    <div class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                        </svg>

                   <span class="text-xl">
                    .
                   </span>
                    <p class="text-xl">
                         {{ $t("tellOfDubai") }} :
                    </p>
                </div>
                <div class="text-xl pl-6">
                    <p dir="ltr">00971503501185</p>
                </div>
                </div>

                  <!-- ///////////////////////////////////////////////////////// -->
                <div class="my-4">
                <div class="flex items-center">
                    <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                    </svg>
                   <span class="text-xl">
                    .
                   </span>
                    <p class="text-xl">
                         {{ $t("addressTehranoffice") }} :
                    </p>
                </div>
                <div class="text-xl pl-6">
                    {{ $t("officeAddressTehran") }} 
                </div>
               </div>
               <!-- ///////////////////////////////////////////////////////// -->
                <div class="my-4">
                    <div class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                        </svg>

                   <span class="text-xl">
                    .
                   </span>
                    <p class="text-xl">
                         {{ $t("tellOfTehran") }} :
                    </p>
                </div>
                <div class="text-xl pl-6">
                    <p dir="ltr">+98 2122222222 </p>
                </div>
                </div>


                <!-- ///////////////////////////////////////////////////////// -->
                <div class="my-4">
                <div class="flex items-center">
                    <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                    </svg>
                   <span class="text-xl">
                    .
                   </span>
                    <p class="text-xl">
                         {{ $t("addressShirazoffice") }} :
                    </p>
                </div>
                <div class="text-xl pl-6">
                    {{ $t("officeAddressShiraz") }} 
                </div>
               </div>
               <!-- ///////////////////////////////////////////////////////// -->
                <div class="my-4">
                    <div class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                        </svg>

                   <span class="text-xl">
                    .
                   </span>
                    <p class="text-xl">
                         {{ $t("tellOfShiraz") }} :
                    </p>
                </div>
                <div class="text-xl pl-6">
                    <p dir="ltr">07137302139</p>
                </div>
                </div>
                
                <!-- /////////////////////////////////////////////////////// -->
                <div class="my-4">
                    <div class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                           <path stroke-linecap="round" stroke-linejoin="round" d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                        </svg>


                   <span class="text-xl">
                    .
                   </span>
                    <p class="text-xl">
                         mine {{ $t("address") }} :
                    </p>
                </div>
                <div class="text-xl pl-6">
                    <p dir="ltr"> {{ $t("addressmain") }}</p>
                </div>
                </div>
                <!-- /////////////////////////////////////////////////////// -->
                <!-- <div class="my-4">
                    <div class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                        </svg>

                   <span class="text-xl">
                    .
                   </span>
                    <p class="text-xl">
                         Mine {{ $t("tell") }} :
                    </p>
                </div>
                <div class="text-xl pl-6">
                    <p dir="ltr">+98 21 22222222</p>
                </div>
                </div> -->
                <!-- /////////////////////////////////////////////////////// -->
                <div class="my-4">
                    <div class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                        </svg>


                   <span class="text-xl">
                    .
                   </span>
                    <p class="text-xl">
                          {{ $t("Managementphone") }} :
                    </p>
                </div>
                <div class="text-xl pl-6">
                    <p dir="ltr">+98 912 004 2254</p>
                </div>
                </div>
                <!-- /////////////////////////////////////////////////////// -->
            </div>

            <div class="m-auto bg-red-200" >
                <img src="@/assets/photos/contact.webp" style="height:700px">
            </div>
       </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>