<template>
    <div class="p-0 m-0 ">
        <nav class="bg-sky-950 z-10 text-black font-extrabold fixed w-full  top-0 left-0"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">

            <div class="mx-auto lg:container text-sky-200 px-2 flex flex-wrap items-center justify-between mx-auto">
                <div class="">
                    <router-link class="" to="/">
                        <img  style="width: 120px;height: 50px;" class="p-0" alt="Vue logo" src="../../assets/logo/logo.png">
                    </router-link>
                </div>

                <div class="lg:order-2 ">
                    <SwitchLang></SwitchLang>
                </div>

                <div class="flex lg:hidden ">
                    <hamIccon @click="open = !open"></hamIccon>
                </div>

                <div :class="open ? 'h-75' : 'h-0'"
                    class=" overflow-hidden lg:h-16 items-center justify-between  w-full lg:flex lg:w-auto lg:order-1 transition-all ease-in-out duration-300"
                    id="navbar-sticky">
                    <ul
                        class="flex   flex-col  mt-1 rounded-lg lg:flex-row  lg:mt-0 lg:text-sm lg:font-medium lg:border-0 py-4">
                        <li class="lg:mx-5">
                            <router-link to="/" href="#"
                                class="text-lg text-xl block font-bold   bg-blue-700 rounded lg:bg-transparent lg:text-blue-700 lg:p-0 "
                                aria-current="page">{{ $t("Home") }}</router-link>
                        </li>

                        <li class="flex cursor-pointer lg:mx-5">
                            <div class="relative xxx flex">
                                <p
                                    class="inline text-lg font-bold  block rounded hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 lg:p-0">
                                    {{ $t("Ourproduct") }}

                                </p>

                                <svg class="w-6 h-6 pt-2  text-black subsymbol    transition  text-lg font-extrabold "
                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="m9 5 7 7-7 7" />
                                </svg>

                            </div>
                            <div class=" hide
          rounded
          absolute
          bg-slate-600
          
          z-10
          p-2
          whitespace-nowrap
          flex
          justify-center
          items-center
          shadow-2xl
          top-6
        ">

                                <ul class="">
                                    <li class="text-lg   hover:bg-black  p-2 rounded-md">
                                        <router-link to="/machinery">
                                            {{ $t("Submenu1") }}
                                        </router-link>
                                    </li>

                                    <li
                                        class="text-lg   mt-2 hover:bg-black  p-2 rounded-md">
                                        <router-link to="/carriers">
                                            {{ $t("Submenu2") }}
                                        </router-link>
                                    </li>
                                </ul>


                            </div>



                        </li>


                        <li class="lg:mx-5">
                            <router-link to="/about"
                                class="text-lg font-bold mr-3 block   rounded hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 lg:p-0">
                                {{ $t("About") }}
                            </router-link>
                        </li>

                        <li class="lg:mx-5">
                            <router-link to="/contact"
                                class="text-lg font-bold block  rounded hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 lg:p-0">
                                {{ $t("Contact") }}
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import hamIccon from "../GlobalComp/HamIcon";
import SwitchLang from "../GlobalComp/SwitchLang";


export default {
    name: "NavbarComp",
    components: {
        hamIccon,
        SwitchLang
    },
    data() {
        return {
            open: false
        };
    }
};
</script>

<style scoped>
.subsymbol {
    left: 6.7rem
}

.hide {
    display: none;
    
    top: 45px
}

.xxx:hover+.hide {

    display: block;
}

.xxx:hover >.subsymbol {
    transform: rotate(90deg);
    
}

.hide:hover {
    display: block;
}
</style>