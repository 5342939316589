<template lang="">
  <div >
    <div class="grid grid-cols-2 gap-7 ">
      div
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="">
  
</style>