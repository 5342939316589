<template class="container">
  <div class="text-center">
    <div class="text-center font-bold text-3xl">
      <span>
        {{ $t("apexia") }}
      </span>
    </div>
    <p class="p-5 overflow-hidden text-xl ">
      {{ $t("aboatSection1") }}
    </p>

    <div class="text-center m-auto rounded-lg" style="height:30%;width:60%">  
      <img class="w-100 h-100" src="@/assets/photos/madan.png">
    </div>

    <p class="p-5 overflow-hidden text-xl ">
      {{ $t("aboatSection2") }}
    </p>

    <div class="text-center m-auto rounded-lg" style="height:30%;width:60%">  
      <img class="w-100 h-100" src="@/assets/photos/madan2.jpeg">
    </div>

    <div class="flex justify-between my-9 overflow-hidden text-xl grid grid-cols-1 lg:grid-cols-2" >
      <div style="width:100%;">
        <P >
        {{ $t("aboatSection3") }}
      </P>
      <div class="mt-5 mx-3">
      
      </div>
      </div>

      <div  style="width:100%;">
        <img class="w-100 h-100" src="@/assets/photos/madan2.jpeg">
      </div>
      <router-link  to="/contact">
                <span class="text-xl font-bold text-blue-600 ">
                    {{ $t("Contact") }}
                </span>
          </router-link>
    </div>

  </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>