<template lang="">
    <div :dir="$i18n.locale == 'ar' ?'rtl':'ltr'">
        <div>
            <h1 class="text-xl font-bold my-6">
                {{ $t("carrierspagetitle") }}
            </h1>
            <p>
                {{ $t("carrierspageexplain") }}
            </p>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 my-10">
            <div class="overflow-hidden ">
                <h2 class="text-xl mb-2 font-bold">{{ $t("billmekaniki") }}</h2>
                <p class="px-2">
                    {{ $t("billmekanikiexplain") }}
                </p>
               
            </div>
            <div>
                    <img src="@/assets/photos/Excavator.jpg" style="width:100%;height:400px">
            </div>
        </div>
<!-- //////////////////////////////////////////////////////////////////////////////////////// -->
<div class="grid grid-cols-1 lg:grid-cols-2 my-10">
            <div class="overflow-hidden ">
                <h2 class="text-xl mb-2 font-bold">{{ $t("Bulldozer") }}</h2>
                <p class="px-2">
                    {{ $t("Bulldozerexplain") }}
                </p>
               
            </div>
            <div>
                    <img src="@/assets/photos/boldozer.webp" style="width:100%;height:400px">
            </div>
        </div>
        <!-- /////////////////////////////////////////////////////////////////////////////////////////// -->
        <div class="grid grid-cols-1 lg:grid-cols-2 my-10">
            <div class="overflow-hidden ">
                <h2 class="text-xl mb-2 font-bold">{{ $t("domtracet") }}</h2>
                <p class="px-2">
                    {{ $t("domtracetexplain") }}
                </p>
               
            </div>
            <div>
                    <img src="@/assets/photos/domptract.jpg" style="width:100%;height:400px">
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>