<template lang="">
   <div class="mt-16">
    <slider></slider>
    <div class="flex  overflow-hidden my-8 grid grid-cols-1 md:grid-cols-2" ref="specialDiv" 
      style="height: 25rem;">

      <div class="overflow-hidden flex justify-center" style="width: 100%;">
        <div ref="lDiv" :class="{ lActive: lIsActive }" class="flex justify-center  h-100   lElement" style="width: 80%;height: 100%;">
            <img src="@/assets/photos/aks4.jpg">
        </div>
      </div>

      <div class="overflow-hidden flex justify-center " style="width: 100%;">
        <div ref="rDiv" :class="{ rActive: rIsActive }" class="  h-100 rElement " style="width: 80%;height: 100%;">
        
            <p class="text-center font-bold text-2xl py-4 ">
                {{ $t("titleHome1") }}
            </p>
      
            <p class="overflow-hidden text-xl">
                {{ $t("explainHome1") }}
            </p>
            <div class="mt-8">
                <router-link  to="/contact">
                <span class="text-xl font-bold text-blue-600 ">
                    {{ $t("Contact") }}
                </span>
            </router-link>
            </div>
        </div>
      </div>

    </div>

    <div class="flex  overflow-hidden my-8 grid grid-cols-1 md:grid-cols-2" ref="specialDiv1" 
      style="height: 25rem;">
      <div class="overflow-hidden flex justify-center " style="width: 100%;">
        <div ref="rDiv" :class="{ rActive1 : rIsActive1 }" class="  h-100 rElement " style="width: 80%;height: 100%;">
        
            <p class="text-center font-bold text-2xl py-4 ">
                {{ $t("titleHome2") }}
            </p>
      
            <p class="overflow-hidden text-xl">
                {{ $t("explainHome2") }}
            </p>
            <div class="mt-8">
                <router-link  to="/">
                <span class="text-xl font-bold text-blue-600 ">
                    Gallery
                </span>
            </router-link>
            </div>
        </div>
      </div>

      <div class="overflow-hidden flex justify-center" style="width: 100%;">
        <div ref="lDiv" :class="{ lActive1 : lIsActive1 }" class="flex justify-center  h-100 mt-6 md:mt-0 lElement" style="width: 80%;height: 100%;">
            <img src="@/assets/photos/aks1.png">
        </div>
      </div>
    </div>

    <!-- <div>
        <nSlider></nSlider>
      </div> -->

   </div>
</template>
<script>
import slider from "../components/slider.vue"
// import nSlider from "../components/bSlideer"
export default {
    components: {
        slider,
        // nSlider
    },
    data() {
        return {
            lIsActive: false,
            rIsActive: false,
            lIsActive1: false,
            rIsActive1: false
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('scroll', this.handleScroll2);
    },
    methods: {
        handleScroll() {
            const specialDiv = this.$refs.specialDiv;

            const bounding = specialDiv.getBoundingClientRect();


            // Check if specialDiv is within the viewport
            if (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            ) {

                this.lIsActive = true
                this.rIsActive = true



            }
        },
        handleScroll2() {
            const specialDiv1 = this.$refs.specialDiv1;

            const bounding = specialDiv1.getBoundingClientRect();


            // Check if specialDiv is within the viewport
            if (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            ) {

                this.lIsActive1 = true
                this.rIsActive1 = true



            }
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('scroll', this.handleScroll2);
    }
}
</script>
<style scoped>
.lElement {
    transition: 1000ms;
    margin-left: -100rem;
}

.rElement {
    transition: 1000ms;
    margin-right: -100rem;
}

.lActive {
    transition: 1000ms;
    margin-left: 0rem;
}

.rActive {
    transition: 1000ms;
    margin-right: 0rem;
}

.lElement1 {
    transition: 1000ms;
    margin-left: -100rem;
}

.rElement1 {
    transition: 1000ms;
    margin-right: -100rem;
}

.lActive1 {
    transition: 1000ms;
    margin-left: 0rem;
}

.rActive1 {
    transition: 1000ms;
    margin-right: 0rem;
}
</style>